.mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.mobile .div {
  background-color: #fff;
  border: 1px;
  width: 376px;
  height: 1926px;
  position: relative;
  overflow: hidden;
}

.mobile .overlap {
  width: 376px;
  height: 108px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .rectangle {
  background-color: #5d2684;
  width: 376px;
  height: 38px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .group {
  width: 376px;
  height: 72px;
  position: absolute;
  top: 36px;
  left: 0;
}

.mobile .bzinga-final-logo {
  width: 107px;
  height: 37px;
  position: absolute;
  top: 17px;
  left: 12px;
}

.mobile .frame {
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 19px;
  left: 219px;
}

.mobile .div-wrapper {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px;
  display: inline-flex;
  position: relative;
}

.mobile .text-wrapper {
  color: #2c2c2c;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Gilroy-SemiBold-☞, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.mobile .group-2 {
  width: 183px;
  height: 34px;
  position: absolute;
  top: 2px;
  left: 97px;
}

.mobile .app-icons {
  align-items: flex-start;
  gap: 4.27px;
  display: inline-flex;
  position: absolute;
  top: 9px;
  left: 146px;
}

.mobile .image-wrapper {
  width: 16.37px;
  height: 16.37px;
  position: relative;
}

.mobile .image {
  width: 14px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 1px;
}

.mobile .img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .get-bzinga-app-on-wrapper {
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .get-bzinga-app-on {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Gilroy-Bold-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.mobile .span {
  color: #fff;
}

.mobile .text-wrapper-2 {
  color: #ffc706;
}

.mobile .play-auction-and {
  object-fit: cover;
  width: 376px;
  height: 212px;
  position: absolute;
  top: 108px;
  left: 0;
}

.mobile .follow-easy-steps {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 299px;
  font-family: Gilroy-ExtraBold-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 366px;
  left: 39px;
}

.mobile .text-wrapper-3 {
  color: #363636;
}

.mobile .text-wrapper-4 {
  color: #ff9c09;
}

.mobile .partner-brands {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 17px;
  font-family: Gilroy-ExtraBold-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 1020px;
  left: 89px;
}

.mobile .frame-2 {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 11.93px;
  display: inline-flex;
  position: absolute;
  top: 1062px;
  left: 12px;
}

.mobile .img-wrapper {
  background-color: #fff;
  border: .23px solid #979797;
  border-radius: 3.75px;
  width: 79.3px;
  height: 30.74px;
  position: relative;
}

.mobile .image-2 {
  width: 53px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 13px;
}

.mobile .image-3 {
  width: 43px;
  height: 19px;
  position: absolute;
  top: 5px;
  left: 18px;
}

.mobile .image-4 {
  object-fit: cover;
  width: 47px;
  height: 25px;
  position: absolute;
  top: 3px;
  left: 16px;
}

.mobile .image-5 {
  object-fit: cover;
  width: 27px;
  height: 27px;
  position: absolute;
  top: 1px;
  left: 26px;
}

.mobile .get-the-app-and-win {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 17px;
  font-family: Gilroy-ExtraBold-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 1139px;
  left: 63px;
}

.mobile .overlap-wrapper {
  background-color: #fff;
  border-radius: 29.33px;
  width: 194px;
  height: 394px;
  position: absolute;
  top: 1181px;
  left: 91px;
  overflow: hidden;
}

.mobile .overlap-group {
  width: 196px;
  height: 411px;
  position: relative;
}

.mobile .overlap-group-wrapper {
  width: 196px;
  height: 411px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .overlap-group-2 {
  width: 194px;
  height: 394px;
  position: relative;
}

.mobile .image-6 {
  object-fit: cover;
  width: 186px;
  height: 386px;
  position: absolute;
  top: 8px;
  left: 4px;
}

.mobile .image-7 {
  width: 194px;
  height: 394px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .rectangle-2 {
  background-color: #0684ff;
  width: 24px;
  height: 9px;
  position: absolute;
  top: 96px;
  left: 83px;
}

.mobile .text-wrapper-5 {
  color: #fff;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 8.8px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 95px;
  left: 87px;
}

.mobile .frame-3 {
  background-color: #fff;
  border-radius: 29.33px;
  width: 194px;
  height: 394px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mobile .overlap-2 {
  height: 394px;
  position: relative;
}

.mobile .microsoftteams-image {
  object-fit: cover;
  width: 184px;
  height: 386px;
  position: absolute;
  top: 8px;
  left: 5px;
}

.mobile .microsoftteams-image-2 {
  width: 28px;
  height: 18px;
  position: absolute;
  top: 110px;
  left: 24px;
}

.mobile .group-3 {
  background-image: url("image-4.7b50d3e9.png");
  background-size: 100% 100%;
  width: 194px;
  height: 394px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .scan-code-to {
  color: #363636;
  letter-spacing: 0;
  width: 196px;
  height: 33px;
  font-family: Gilroy-ExtraBold-☞, Helvetica;
  font-size: 15.6px;
  font-weight: 400;
  line-height: 15.6px;
  position: absolute;
  top: 1780px;
  left: 169px;
}

.mobile .frame-4 {
  align-items: flex-start;
  gap: 4.91px;
  display: inline-flex;
  position: absolute;
  top: 1688px;
  left: 91px;
}

.mobile .image-8 {
  width: 94.78px;
  height: 28.52px;
  position: relative;
}

.mobile .image-9 {
  width: 93.86px;
  height: 28.22px;
  position: relative;
}

.mobile .bzinga-app-QR {
  width: 113px;
  height: 113px;
  position: absolute;
  top: 1741px;
  left: 42px;
}

.mobile .frame-5 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10.39px;
  display: inline-flex;
  position: absolute;
  top: 1606px;
  left: 94px;
}

.mobile .p {
  color: #393939;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.43px;
  font-family: Gilroy-Medium-☞, Helvetica;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.mobile .text-wrapper-6 {
  color: #393939;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Gilroy-Medium-☞, Helvetica;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.mobile .ellipse {
  background-color: #3d1b7f;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 1608px;
  left: 75px;
}

.mobile .ellipse-2 {
  background-color: #3d1b7f;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 1630px;
  left: 75px;
}

.mobile .ellipse-3 {
  background-color: #3d1b7f;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 1660px;
  left: 75px;
}

.mobile .p-wrapper {
  background-color: #ff9c09;
  width: 376px;
  height: 24px;
  position: absolute;
  top: 1902px;
  left: 0;
}

.mobile .text-wrapper-7 {
  color: #393939;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 8px;
  font-family: Gilroy-SemiBold-☞, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 7px;
  left: 20px;
}

.mobile .group-4 {
  width: 369px;
  height: 289px;
  position: absolute;
  top: 444px;
  left: 8px;
}

.mobile .layer {
  object-fit: cover;
  width: 49px;
  height: 39px;
  position: absolute;
  top: 42px;
  left: 281px;
}

.mobile .overlap-3 {
  width: 85px;
  height: 192px;
  position: absolute;
  top: 54px;
  left: 186px;
}

.mobile .frame-wrapper {
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 22.07px;
  width: 1px;
  height: 172px;
  display: flex;
  position: absolute;
  top: 0;
  left: 49px;
}

.mobile .frame-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10.01px;
  display: inline-flex;
  position: relative;
}

.mobile .text {
  color: #3d1b7f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.29px;
  font-family: Gilroy-Black-☞, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.mobile .frame-7 {
  background-color: #fff;
  border: 1.18px solid #d3bdff;
  border-radius: 14.13px;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 107px;
  left: 0;
  overflow: hidden;
}

.mobile .image-10 {
  width: 82px;
  height: 82px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .frame-8 {
  background-color: #fff;
  border: 1.18px solid #d3bdff;
  border-radius: 14.13px;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 14px;
  left: 0;
  overflow: hidden;
}

.mobile .rectangle-wrapper {
  background-image: url("image-264.3006b7b2.png");
  background-position: 50%;
  background-size: cover;
  width: 82px;
  height: 82px;
}

.mobile .rectangle-3 {
  background-color: #ffbe19;
  width: 42px;
  height: 9px;
  position: relative;
  top: 29px;
  left: 20px;
}

.mobile .frame-9 {
  background-color: #fff;
  border: 1.18px solid #d3bdff;
  border-radius: 14.13px;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 137px;
  left: 93px;
  overflow: hidden;
}

.mobile .image-11 {
  object-fit: cover;
  width: 82px;
  height: 82px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .frame-10 {
  background-color: #fff;
  border: 1.18px solid #d3bdff;
  border-radius: 14.13px;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 44px;
  left: 93px;
  overflow: hidden;
}

.mobile .get-the-app-login {
  color: #3d1b7f;
  letter-spacing: 0;
  text-align: center;
  width: 159px;
  height: 25px;
  font-family: Gilroy-Bold-☞, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 10px;
  left: 0;
}

.mobile .play-for-your {
  color: #3d1b7f;
  letter-spacing: 0;
  text-align: center;
  width: 171px;
  height: 25px;
  font-family: Gilroy-Bold-☞, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 264px;
  left: 17px;
}

.mobile .text-wrapper-8 {
  color: #3d1b7f;
  letter-spacing: 0;
  text-align: center;
  width: 125px;
  height: 25px;
  font-family: Gilroy-Bold-☞, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 0;
  left: 236px;
}

.mobile .text-wrapper-9 {
  color: #3d1b7f;
  letter-spacing: 0;
  text-align: center;
  width: 85px;
  height: 25px;
  font-family: Gilroy-Bold-☞, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: absolute;
  top: 260px;
  left: 274px;
}

.mobile .layer-2 {
  object-fit: cover;
  width: 44px;
  height: 35px;
  position: absolute;
  top: 217px;
  left: 286px;
}

.mobile .layer-3 {
  object-fit: cover;
  width: 44px;
  height: 35px;
  position: absolute;
  top: 52px;
  left: 36px;
}

.mobile .layer-4 {
  object-fit: cover;
  width: 28px;
  height: 29px;
  position: absolute;
  top: 223px;
  left: 66px;
}

.mobile .group-5 {
  width: 380px;
  height: 186px;
  position: absolute;
  top: 780px;
  left: 0;
}

.mobile .overlap-4 {
  width: 376px;
  height: 186px;
  position: relative;
}

.mobile .get-the-app-and-win-2 {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 12px;
  font-family: Gilroy-ExtraBold-Regular, Helvetica;
  font-size: 17.4px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 19px;
  left: 107px;
}

.mobile .group-6 {
  background-color: #3d1b7f;
  width: 376px;
  height: 186px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .win-products-from {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 12px;
  font-family: Gilroy-ExtraBold-Regular, Helvetica;
  font-size: 17.4px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 19px;
  left: 42px;
}

.mobile .frame-11 {
  align-items: flex-start;
  gap: 16.71px;
  width: 311px;
  height: 92px;
  display: flex;
  position: absolute;
  top: 62px;
  left: 33px;
}

.mobile .frame-12 {
  background-color: #fff;
  border: 1.04px solid #8856e8;
  border-radius: 2.09px;
  width: 94.46px;
  height: 94.46px;
  margin-top: -1.04px;
  margin-bottom: -1.04px;
  margin-left: -1.04px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.04px 7.83px #d3bdff;
}

.mobile .image-12 {
  object-fit: cover;
  width: 84px;
  height: 89px;
  position: absolute;
  top: 0;
  left: 4px;
}

.mobile .frame-13 {
  background-color: #fff;
  border: 1.04px solid #8856e8;
  border-radius: 2.09px;
  width: 94.46px;
  height: 94.46px;
  margin-top: -1.04px;
  margin-bottom: -1.04px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.04px 7.83px #d3bdff;
}

.mobile .image-13 {
  object-fit: cover;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 4px;
  left: 4px;
}

.mobile .frame-14 {
  background-color: #fff;
  border: 1.04px solid #8856e8;
  border-radius: 2.09px;
  width: 94.46px;
  height: 94.46px;
  margin-top: -1.04px;
  margin-bottom: -1.04px;
  margin-right: -1.04px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.04px 7.83px #d3bdff;
}

.mobile .overlap-group-3 {
  width: 106px;
  height: 89px;
  position: relative;
  top: 2px;
}

.mobile .image-14 {
  object-fit: cover;
  width: 92px;
  height: 89px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .rectangle-4 {
  background-color: #fff;
  width: 23px;
  height: 69px;
  position: absolute;
  top: 2px;
  left: 83px;
}

.desktop {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.desktop .div {
  background-color: #fff;
  border: 1px;
  width: 1440px;
  height: 4182px;
  position: relative;
  overflow: hidden;
}

.desktop .group {
  width: 1440px;
  height: 77px;
  position: absolute;
  top: 72px;
  left: 0;
}

.desktop .frame {
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 19px;
  left: 605px;
}

.desktop .div-wrapper {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px;
  display: inline-flex;
  position: relative;
}

.desktop .text-wrapper {
  color: #2c2c2c;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Gilroy-SemiBold-☞, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.desktop .bzinga-final-logo {
  width: 223px;
  height: 77px;
  position: absolute;
  top: 0;
  left: 71px;
}

.desktop .app-icons {
  align-items: flex-start;
  gap: 7.15px;
  display: inline-flex;
  position: absolute;
  top: 25px;
  left: 1306px;
}

.desktop .image-wrapper {
  background-color: #fff;
  width: 27.42px;
  height: 27.42px;
  position: relative;
}

.desktop .image {
  width: 23px;
  height: 26px;
  position: absolute;
  top: 1px;
  left: 2px;
}

.desktop .img {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.desktop .get-bzinga-app-on-wrapper {
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px;
  display: inline-flex;
  position: absolute;
  top: 20px;
  left: 1094px;
}

.desktop .get-bzinga-app-on {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Gilroy-Bold-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.desktop .span {
  color: #2c2c2c;
}

.desktop .text-wrapper-2 {
  color: #411b5c;
}

.desktop .overlap {
  width: 1291px;
  height: 976px;
  position: absolute;
  top: 1062px;
  left: 72px;
}

.desktop .follow-easy-steps {
  color: #0000;
  letter-spacing: 0;
  width: 866px;
  font-family: Gilroy-ExtraBold-Regular, Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .text-wrapper-3 {
  color: #363636;
}

.desktop .text-wrapper-4 {
  color: #ff9c09;
}

.desktop .group-2 {
  width: 1277px;
  height: 846px;
  position: absolute;
  top: 130px;
  left: 14px;
}

.desktop .layer {
  object-fit: cover;
  width: 166px;
  height: 131px;
  position: absolute;
  top: 105px;
  left: 974px;
}

.desktop .overlap-group {
  width: 288px;
  height: 653px;
  position: absolute;
  top: 146px;
  left: 654px;
}

.desktop .frame-wrapper {
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 75px;
  height: 586px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 168px;
}

.desktop .frame-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 34px;
  display: inline-flex;
  position: relative;
}

.desktop .text {
  color: #3d1b7f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Gilroy-Black-☞, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 57.6px;
  position: relative;
}

.desktop .img-wrapper {
  background-color: #fff;
  border: 4px solid #d3bdff;
  border-radius: 48px;
  width: 288px;
  height: 288px;
  position: absolute;
  top: 365px;
  left: 0;
  overflow: hidden;
}

.desktop .image-2 {
  width: 280px;
  height: 280px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .overlap-group-wrapper {
  background-color: #fff;
  border: 4px solid #d3bdff;
  border-radius: 48px;
  width: 288px;
  height: 288px;
  position: absolute;
  top: 49px;
  left: 0;
  overflow: hidden;
}

.desktop .rectangle-wrapper {
  background-image: url("image-264.3006b7b2.png");
  background-position: 50%;
  background-size: cover;
  width: 280px;
  height: 280px;
}

.desktop .rectangle {
  background-color: #ffbe19;
  width: 143px;
  height: 29px;
  position: relative;
  top: 98px;
  left: 69px;
}

.desktop .frame-3 {
  background-color: #fff;
  border: 4px solid #d3bdff;
  border-radius: 48px;
  width: 288px;
  height: 288px;
  position: absolute;
  top: 429px;
  left: 335px;
  overflow: hidden;
}

.desktop .image-3 {
  object-fit: cover;
  width: 280px;
  height: 280px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .frame-4 {
  background-color: #fff;
  border: 4px solid #d3bdff;
  border-radius: 48px;
  width: 288px;
  height: 288px;
  position: absolute;
  top: 113px;
  left: 335px;
  overflow: hidden;
}

.desktop .get-the-app-login {
  color: #3d1b7f;
  letter-spacing: 0;
  text-align: center;
  width: 277px;
  height: 51px;
  font-family: Gilroy-Bold-☞, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 174px;
  left: 0;
}

.desktop .play-for-your {
  color: #3d1b7f;
  letter-spacing: 0;
  text-align: center;
  width: 246px;
  height: 85px;
  font-family: Gilroy-Bold-☞, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 761px;
  left: 77px;
}

.desktop .p {
  color: #3d1b7f;
  letter-spacing: 0;
  text-align: center;
  width: 235px;
  height: 51px;
  font-family: Gilroy-Bold-☞, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 0;
  left: 1013px;
}

.desktop .text-wrapper-5 {
  color: #3d1b7f;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Gilroy-Bold-☞, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  position: absolute;
  top: 689px;
  left: 1009px;
}

.desktop .layer-2 {
  object-fit: cover;
  width: 149px;
  height: 118px;
  position: absolute;
  top: 537px;
  left: 982px;
}

.desktop .layer-3 {
  object-fit: cover;
  width: 149px;
  height: 118px;
  position: absolute;
  top: 243px;
  left: 127px;
}

.desktop .layer-4 {
  object-fit: cover;
  width: 96px;
  height: 99px;
  position: absolute;
  top: 614px;
  left: 189px;
}

.desktop .get-the-app-and-win {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 47px;
  font-family: Gilroy-ExtraBold-Regular, Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 3232px;
  left: 64px;
}

.desktop .text-2 {
  color: #363636;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 47px;
  font-family: Gilroy-ExtraBold-☞, Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 2895px;
  left: 342px;
}

.desktop .overlap-2 {
  width: 1440px;
  height: 714px;
  position: absolute;
  top: 2110px;
  left: 0;
}

.desktop .get-the-app-and-win-2 {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 47px;
  font-family: Gilroy-ExtraBold-Regular, Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 70px;
  left: 409px;
}

.desktop .group-3 {
  background-color: #3d1b7f;
  width: 1440px;
  height: 714px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .win-products-from {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 47px;
  font-family: Gilroy-ExtraBold-Regular, Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 71px;
  left: 160px;
}

.desktop .text-wrapper-6 {
  color: #fff;
}

.desktop .frame-5 {
  align-items: flex-start;
  gap: 64px;
  display: inline-flex;
  position: absolute;
  top: 239px;
  left: 125px;
}

.desktop .frame-6 {
  background-color: #fff;
  border: 4px solid #8856e8;
  border-radius: 8px;
  width: 361.75px;
  height: 361.75px;
  margin-top: -4px;
  margin-bottom: -4px;
  margin-left: -4px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 30px #d3bdff;
}

.desktop .image-4 {
  object-fit: cover;
  width: 322px;
  height: 342px;
  position: absolute;
  top: 0;
  left: 16px;
}

.desktop .frame-7 {
  background-color: #fff;
  border: 4px solid #8856e8;
  border-radius: 8px;
  width: 361.75px;
  height: 361.75px;
  margin-top: -4px;
  margin-bottom: -4px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 30px #d3bdff;
}

.desktop .image-5 {
  object-fit: cover;
  width: 326px;
  height: 326px;
  position: absolute;
  top: 14px;
  left: 14px;
}

.desktop .frame-8 {
  background-color: #fff;
  border: 4px solid #8856e8;
  border-radius: 8px;
  width: 361.75px;
  height: 361.75px;
  margin-top: -4px;
  margin-bottom: -4px;
  margin-right: -4px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 30px #d3bdff;
}

.desktop .overlap-group-2 {
  width: 406px;
  height: 342px;
  position: relative;
  top: 6px;
}

.desktop .image-6 {
  object-fit: cover;
  width: 354px;
  height: 342px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .rectangle-2 {
  background-color: #fff;
  width: 88px;
  height: 264px;
  position: absolute;
  top: 7px;
  left: 318px;
}

.desktop .scan-code-to {
  color: #363636;
  letter-spacing: 0;
  width: 452px;
  height: 75px;
  font-family: Gilroy-ExtraBold-☞, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 3761px;
  left: 885px;
}

.desktop .overlap-wrapper {
  background-color: #fff;
  border-radius: 40.06px;
  width: 265px;
  height: 538px;
  position: absolute;
  top: 3494px;
  left: 169px;
  overflow: hidden;
}

.desktop .overlap-3 {
  width: 267px;
  height: 561px;
  position: relative;
}

.desktop .group-4 {
  width: 267px;
  height: 561px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .overlap-group-3 {
  width: 265px;
  height: 538px;
  position: relative;
}

.desktop .image-7 {
  object-fit: cover;
  width: 254px;
  height: 527px;
  position: absolute;
  top: 11px;
  left: 6px;
}

.desktop .image-8 {
  width: 265px;
  height: 538px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .rectangle-3 {
  background-color: #0684ff;
  width: 33px;
  height: 12px;
  position: absolute;
  top: 131px;
  left: 114px;
}

.desktop .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 129px;
  left: 119px;
}

.desktop .frame-9 {
  background-color: #fff;
  border-radius: 40.06px;
  width: 265px;
  height: 538px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.desktop .overlap-4 {
  height: 538px;
  position: relative;
}

.desktop .microsoftteams-image {
  object-fit: cover;
  width: 252px;
  height: 527px;
  position: absolute;
  top: 11px;
  left: 7px;
}

.desktop .microsoftteams-image-2 {
  width: 38px;
  height: 24px;
  position: absolute;
  top: 150px;
  left: 33px;
}

.desktop .group-5 {
  background-image: url("image-4.7b50d3e9.png");
  background-size: 100% 100%;
  width: 265px;
  height: 538px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .frame-10 {
  align-items: flex-start;
  gap: 11.33px;
  display: inline-flex;
  position: absolute;
  top: 3571px;
  left: 596px;
}

.desktop .image-9 {
  width: 218.9px;
  height: 65.88px;
  position: relative;
}

.desktop .image-10 {
  width: 216.77px;
  height: 65.17px;
  position: relative;
}

.desktop .frame-11 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 3422px;
  left: 628px;
}

.desktop .text-wrapper-8 {
  color: #393939;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Gilroy-Medium-☞, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.7px;
  position: relative;
}

.desktop .text-wrapper-9 {
  color: #393939;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Gilroy-Medium-☞, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.7px;
  position: relative;
}

.desktop .bzinga-app-QR {
  width: 261px;
  height: 261px;
  position: absolute;
  top: 3763px;
  left: 591px;
}

.desktop .overlap-5 {
  width: 1440px;
  height: 810px;
  position: absolute;
  top: 181px;
  left: 0;
}

.desktop .frame-12 {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 799px;
  left: 699px;
}

.desktop .ellipse {
  background-color: #5d2684;
  border-radius: 5.33px;
  width: 10.67px;
  height: 10.67px;
  position: relative;
}

.desktop .ellipse-2 {
  background-color: #f1ddff;
  border-radius: 3.81px;
  width: 7.62px;
  height: 7.62px;
  position: relative;
}

.desktop .play-auction-and {
  object-fit: cover;
  width: 1440px;
  height: 810px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .frame-13 {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 92px;
  display: inline-flex;
  position: absolute;
  top: 3015px;
  left: 76px;
}

.desktop .group-6 {
  background-color: #fff;
  border: .75px solid #979797;
  border-radius: 11.96px;
  width: 252.6px;
  height: 97.9px;
  position: relative;
}

.desktop .image-11 {
  width: 169px;
  height: 38px;
  position: absolute;
  top: 29px;
  left: 41px;
}

.desktop .image-12 {
  width: 137px;
  height: 62px;
  position: absolute;
  top: 17px;
  left: 58px;
}

.desktop .image-13 {
  object-fit: cover;
  width: 151px;
  height: 79px;
  position: absolute;
  top: 9px;
  left: 50px;
}

.desktop .image-14 {
  object-fit: cover;
  width: 87px;
  height: 87px;
  position: absolute;
  top: 5px;
  left: 82px;
}

.desktop .group-7 {
  width: 1442px;
  height: 48px;
  position: absolute;
  top: 4134px;
  left: 0;
}

.desktop .p-wrapper {
  background-color: #ff9c09;
  width: 1440px;
  height: 48px;
  position: relative;
}

.desktop .text-wrapper-10 {
  color: #393939;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 14px;
  font-family: Gilroy-SemiBold-☞, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 16px;
  left: 440px;
}

.desktop .ellipse-3 {
  background-color: #3d1b7f;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3422px;
  left: 596px;
}

.desktop .ellipse-4 {
  background-color: #3d1b7f;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3462px;
  left: 596px;
}

.desktop .ellipse-5 {
  background-color: #3d1b7f;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3500px;
  left: 596px;
}

/*# sourceMappingURL=index.98bb6f47.css.map */
