.mobile {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.mobile .div {
  background-color: #ffffff;
  border: 1px none;
  height: 1926px;
  overflow: hidden;
  position: relative;
  width: 376px;
}

.mobile .overlap {
  height: 108px;
  left: 0;
  position: absolute;
  top: 0;
  width: 376px;
}

.mobile .rectangle {
  background-color: #5d2684;
  height: 38px;
  left: 0;
  position: absolute;
  top: 0;
  width: 376px;
}

.mobile .group {
  height: 72px;
  left: 0;
  position: absolute;
  top: 36px;
  width: 376px;
}

.mobile .bzinga-final-logo {
  height: 37px;
  left: 12px;
  position: absolute;
  top: 17px;
  width: 107px;
}

.mobile .frame {
  align-items: flex-start;
  display: inline-flex;
  left: 219px;
  position: absolute;
  top: 19px;
}

.mobile .div-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 12px;
  position: relative;
}

.mobile .text-wrapper {
  color: #2c2c2c;
  font-family: "Gilroy-SemiBold-☞", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.mobile .group-2 {
  height: 34px;
  left: 97px;
  position: absolute;
  top: 2px;
  width: 183px;
}

.mobile .app-icons {
  align-items: flex-start;
  display: inline-flex;
  gap: 4.27px;
  left: 146px;
  position: absolute;
  top: 9px;
}

.mobile .image-wrapper {
  height: 16.37px;
  position: relative;
  width: 16.37px;
}

.mobile .image {
  height: 16px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 14px;
}

.mobile .img {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.mobile .get-bzinga-app-on-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 12px;
  position: absolute;
  top: 0;
}

.mobile .get-bzinga-app-on {
  color: transparent;
  font-family: "Gilroy-Bold-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.mobile .span {
  color: #ffffff;
}

.mobile .text-wrapper-2 {
  color: #ffc706;
}

.mobile .play-auction-and {
  height: 212px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 108px;
  width: 376px;
}

.mobile .follow-easy-steps {
  color: transparent;
  font-family: "Gilroy-ExtraBold-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 366px;
  width: 299px;
}

.mobile .text-wrapper-3 {
  color: #363636;
}

.mobile .text-wrapper-4 {
  color: #ff9c09;
}

.mobile .partner-brands {
  color: transparent;
  font-family: "Gilroy-ExtraBold-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 17px;
  left: 89px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1020px;
  white-space: nowrap;
}

.mobile .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11.93px;
  justify-content: flex-end;
  left: 12px;
  position: absolute;
  top: 1062px;
}

.mobile .img-wrapper {
  background-color: #ffffff;
  border: 0.23px solid;
  border-color: #979797;
  border-radius: 3.75px;
  height: 30.74px;
  position: relative;
  width: 79.3px;
}

.mobile .image-2 {
  height: 12px;
  left: 13px;
  position: absolute;
  top: 9px;
  width: 53px;
}

.mobile .image-3 {
  height: 19px;
  left: 18px;
  position: absolute;
  top: 5px;
  width: 43px;
}

.mobile .image-4 {
  height: 25px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 47px;
}

.mobile .image-5 {
  height: 27px;
  left: 26px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 27px;
}

.mobile .get-the-app-and-win {
  color: transparent;
  font-family: "Gilroy-ExtraBold-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 17px;
  left: 63px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1139px;
  white-space: nowrap;
}

.mobile .overlap-wrapper {
  background-color: #ffffff;
  border-radius: 29.33px;
  height: 394px;
  left: 91px;
  overflow: hidden;
  position: absolute;
  top: 1181px;
  width: 194px;
}

.mobile .overlap-group {
  height: 411px;
  position: relative;
  width: 196px;
}

.mobile .overlap-group-wrapper {
  height: 411px;
  left: 0;
  position: absolute;
  top: 0;
  width: 196px;
}

.mobile .overlap-group-2 {
  height: 394px;
  position: relative;
  width: 194px;
}

.mobile .image-6 {
  height: 386px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 186px;
}

.mobile .image-7 {
  height: 394px;
  left: 0;
  position: absolute;
  top: 0;
  width: 194px;
}

.mobile .rectangle-2 {
  background-color: #0684ff;
  height: 9px;
  left: 83px;
  position: absolute;
  top: 96px;
  width: 24px;
}

.mobile .text-wrapper-5 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 8.8px;
  font-weight: 500;
  left: 87px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 95px;
}

.mobile .frame-3 {
  background-color: #ffffff;
  border-radius: 29.33px;
  height: 394px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 194px;
}

.mobile .overlap-2 {
  height: 394px;
  position: relative;
}

.mobile .microsoftteams-image {
  height: 386px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 184px;
}

.mobile .microsoftteams-image-2 {
  height: 18px;
  left: 24px;
  position: absolute;
  top: 110px;
  width: 28px;
}

.mobile .group-3 {
  background-image: url(../../../static/img/image-4.png);
  background-size: 100% 100%;
  height: 394px;
  left: 0;
  position: absolute;
  top: 0;
  width: 194px;
}

.mobile .scan-code-to {
  color: #363636;
  font-family: "Gilroy-ExtraBold-☞", Helvetica;
  font-size: 15.6px;
  font-weight: 400;
  height: 33px;
  left: 169px;
  letter-spacing: 0;
  line-height: 15.6px;
  position: absolute;
  top: 1780px;
  width: 196px;
}

.mobile .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  gap: 4.91px;
  left: 91px;
  position: absolute;
  top: 1688px;
}

.mobile .image-8 {
  height: 28.52px;
  position: relative;
  width: 94.78px;
}

.mobile .image-9 {
  height: 28.22px;
  position: relative;
  width: 93.86px;
}

.mobile .bzinga-app-QR {
  height: 113px;
  left: 42px;
  position: absolute;
  top: 1741px;
  width: 113px;
}

.mobile .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10.39px;
  justify-content: center;
  left: 94px;
  position: absolute;
  top: 1606px;
}

.mobile .p {
  color: #393939;
  font-family: "Gilroy-Medium-☞", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  /* line-height: 189.5px; */
  margin-top: -0.43px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.mobile .text-wrapper-6 {
  color: #393939;
  font-family: "Gilroy-Medium-☞", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  /* line-height: 189.5px; */
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.mobile .ellipse {
  background-color: #3d1b7f;
  border-radius: 4px;
  height: 8px;
  left: 75px;
  position: absolute;
  top: 1608px;
  width: 8px;
}

.mobile .ellipse-2 {
  background-color: #3d1b7f;
  border-radius: 4px;
  height: 8px;
  left: 75px;
  position: absolute;
  top: 1630px;
  width: 8px;
}

.mobile .ellipse-3 {
  background-color: #3d1b7f;
  border-radius: 4px;
  height: 8px;
  left: 75px;
  position: absolute;
  top: 1660px;
  width: 8px;
}

.mobile .p-wrapper {
  background-color: #ff9c09;
  height: 24px;
  left: 0;
  position: absolute;
  top: 1902px;
  width: 376px;
}

.mobile .text-wrapper-7 {
  color: #393939;
  font-family: "Gilroy-SemiBold-☞", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 8px;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  white-space: nowrap;
}

.mobile .group-4 {
  height: 289px;
  left: 8px;
  position: absolute;
  top: 444px;
  width: 369px;
}

.mobile .layer {
  height: 39px;
  left: 281px;
  object-fit: cover;
  position: absolute;
  top: 42px;
  width: 49px;
}

.mobile .overlap-3 {
  height: 192px;
  left: 186px;
  position: absolute;
  top: 54px;
  width: 85px;
}

.mobile .frame-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 22.07px;
  height: 172px;
  justify-content: space-around;
  left: 49px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.01px;
  position: relative;
}

.mobile .text {
  color: #3d1b7f;
  font-family: "Gilroy-Black-☞", Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: -0.29px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.mobile .frame-7 {
  background-color: #ffffff;
  border: 1.18px solid;
  border-color: #d3bdff;
  border-radius: 14.13px;
  height: 85px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 107px;
  width: 85px;
}

.mobile .image-10 {
  height: 82px;
  left: 0;
  position: absolute;
  top: 0;
  width: 82px;
}

.mobile .frame-8 {
  background-color: #ffffff;
  border: 1.18px solid;
  border-color: #d3bdff;
  border-radius: 14.13px;
  height: 85px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 14px;
  width: 85px;
}

.mobile .rectangle-wrapper {
  background-image: url(../../../static/img/image-264.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 82px;
  width: 82px;
}

.mobile .rectangle-3 {
  background-color: #ffbe19;
  height: 9px;
  left: 20px;
  position: relative;
  top: 29px;
  width: 42px;
}

.mobile .frame-9 {
  background-color: #ffffff;
  border: 1.18px solid;
  border-color: #d3bdff;
  border-radius: 14.13px;
  height: 85px;
  left: 93px;
  overflow: hidden;
  position: absolute;
  top: 137px;
  width: 85px;
}

.mobile .image-11 {
  height: 82px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 82px;
}

.mobile .frame-10 {
  background-color: #ffffff;
  border: 1.18px solid;
  border-color: #d3bdff;
  border-radius: 14.13px;
  height: 85px;
  left: 93px;
  overflow: hidden;
  position: absolute;
  top: 44px;
  width: 85px;
}

.mobile .get-the-app-login {
  color: #3d1b7f;
  font-family: "Gilroy-Bold-☞", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 159px;
}

.mobile .play-for-your {
  color: #3d1b7f;
  font-family: "Gilroy-Bold-☞", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 25px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: center;
  top: 264px;
  width: 171px;
}

.mobile .text-wrapper-8 {
  color: #3d1b7f;
  font-family: "Gilroy-Bold-☞", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 25px;
  left: 236px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 125px;
}

.mobile .text-wrapper-9 {
  color: #3d1b7f;
  font-family: "Gilroy-Bold-☞", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 25px;
  left: 274px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: center;
  top: 260px;
  width: 85px;
}

.mobile .layer-2 {
  height: 35px;
  left: 286px;
  object-fit: cover;
  position: absolute;
  top: 217px;
  width: 44px;
}

.mobile .layer-3 {
  height: 35px;
  left: 36px;
  object-fit: cover;
  position: absolute;
  top: 52px;
  width: 44px;
}

.mobile .layer-4 {
  height: 29px;
  left: 66px;
  object-fit: cover;
  position: absolute;
  top: 223px;
  width: 28px;
}

.mobile .group-5 {
  height: 186px;
  left: 0;
  position: absolute;
  top: 780px;
  width: 380px;
}

.mobile .overlap-4 {
  height: 186px;
  position: relative;
  width: 376px;
}

.mobile .get-the-app-and-win-2 {
  color: transparent;
  font-family: "Gilroy-ExtraBold-Regular", Helvetica;
  font-size: 17.4px;
  font-weight: 400;
  height: 12px;
  left: 107px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
}

.mobile .group-6 {
  background-color: #3d1b7f;
  height: 186px;
  left: 0;
  position: absolute;
  top: 0;
  width: 376px;
}

.mobile .win-products-from {
  color: transparent;
  font-family: "Gilroy-ExtraBold-Regular", Helvetica;
  font-size: 17.4px;
  font-weight: 400;
  height: 12px;
  left: 42px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
}

.mobile .frame-11 {
  align-items: flex-start;
  display: flex;
  gap: 16.71px;
  height: 92px;
  left: 33px;
  position: absolute;
  top: 62px;
  width: 311px;
}

.mobile .frame-12 {
  background-color: #ffffff;
  border: 1.04px solid;
  border-color: #8856e8;
  border-radius: 2.09px;
  box-shadow: 0px 1.04px 7.83px #d3bdff;
  height: 94.46px;
  margin-bottom: -1.04px;
  margin-left: -1.04px;
  margin-top: -1.04px;
  overflow: hidden;
  position: relative;
  width: 94.46px;
}

.mobile .image-12 {
  height: 89px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 84px;
}

.mobile .frame-13 {
  background-color: #ffffff;
  border: 1.04px solid;
  border-color: #8856e8;
  border-radius: 2.09px;
  box-shadow: 0px 1.04px 7.83px #d3bdff;
  height: 94.46px;
  margin-bottom: -1.04px;
  margin-top: -1.04px;
  overflow: hidden;
  position: relative;
  width: 94.46px;
}

.mobile .image-13 {
  height: 85px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 85px;
}

.mobile .frame-14 {
  background-color: #ffffff;
  border: 1.04px solid;
  border-color: #8856e8;
  border-radius: 2.09px;
  box-shadow: 0px 1.04px 7.83px #d3bdff;
  height: 94.46px;
  margin-bottom: -1.04px;
  margin-right: -1.04px;
  margin-top: -1.04px;
  overflow: hidden;
  position: relative;
  width: 94.46px;
}

.mobile .overlap-group-3 {
  height: 89px;
  position: relative;
  top: 2px;
  width: 106px;
}

.mobile .image-14 {
  height: 89px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 92px;
}

.mobile .rectangle-4 {
  background-color: #ffffff;
  height: 69px;
  left: 83px;
  position: absolute;
  top: 2px;
  width: 23px;
}
