.desktop {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.desktop .div {
  background-color: #ffffff;
  border: 1px none;
  height: 4182px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.desktop .group {
  height: 77px;
  left: 0;
  position: absolute;
  top: 72px;
  width: 1440px;
}

.desktop .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 24px;
  left: 605px;
  position: absolute;
  top: 19px;
}

.desktop .div-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 12px;
  position: relative;
}

.desktop .text-wrapper {
  color: #2c2c2c;
  font-family: "Gilroy-SemiBold-☞", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.desktop .bzinga-final-logo {
  height: 77px;
  left: 71px;
  position: absolute;
  top: 0;
  width: 223px;
}

.desktop .app-icons {
  align-items: flex-start;
  display: inline-flex;
  gap: 7.15px;
  left: 1306px;
  position: absolute;
  top: 25px;
}

.desktop .image-wrapper {
  background-color: #ffffff;
  height: 27.42px;
  position: relative;
  width: 27.42px;
}

.desktop .image {
  height: 26px;
  left: 2px;
  position: absolute;
  top: 1px;
  width: 23px;
}

.desktop .img {
  height: 26px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 26px;
}

.desktop .get-bzinga-app-on-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 1094px;
  padding: 12px;
  position: absolute;
  top: 20px;
}

.desktop .get-bzinga-app-on {
  color: transparent;
  font-family: "Gilroy-Bold-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.desktop .span {
  color: #2c2c2c;
}

.desktop .text-wrapper-2 {
  color: #411b5c;
}

.desktop .overlap {
  height: 976px;
  left: 72px;
  position: absolute;
  top: 1062px;
  width: 1291px;
}

.desktop .follow-easy-steps {
  color: transparent;
  font-family: "Gilroy-ExtraBold-Regular", Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 866px;
}

.desktop .text-wrapper-3 {
  color: #363636;
}

.desktop .text-wrapper-4 {
  color: #ff9c09;
}

.desktop .group-2 {
  height: 846px;
  left: 14px;
  position: absolute;
  top: 130px;
  width: 1277px;
}

.desktop .layer {
  height: 131px;
  left: 974px;
  object-fit: cover;
  position: absolute;
  top: 105px;
  width: 166px;
}

.desktop .overlap-group {
  height: 653px;
  left: 654px;
  position: absolute;
  top: 146px;
  width: 288px;
}

.desktop .frame-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 75px;
  height: 586px;
  justify-content: space-around;
  left: 168px;
  position: absolute;
  top: 0;
}

.desktop .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 34px;
  position: relative;
}

.desktop .text {
  color: #3d1b7f;
  font-family: "Gilroy-Black-☞", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 57.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.desktop .img-wrapper {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #d3bdff;
  border-radius: 48px;
  height: 288px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 365px;
  width: 288px;
}

.desktop .image-2 {
  height: 280px;
  left: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.desktop .overlap-group-wrapper {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #d3bdff;
  border-radius: 48px;
  height: 288px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 49px;
  width: 288px;
}

.desktop .rectangle-wrapper {
  background-image: url(../../../static/img/image-264.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 280px;
  width: 280px;
}

.desktop .rectangle {
  background-color: #ffbe19;
  height: 29px;
  left: 69px;
  position: relative;
  top: 98px;
  width: 143px;
}

.desktop .frame-3 {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #d3bdff;
  border-radius: 48px;
  height: 288px;
  left: 335px;
  overflow: hidden;
  position: absolute;
  top: 429px;
  width: 288px;
}

.desktop .image-3 {
  height: 280px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 280px;
}

.desktop .frame-4 {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #d3bdff;
  border-radius: 48px;
  height: 288px;
  left: 335px;
  overflow: hidden;
  position: absolute;
  top: 113px;
  width: 288px;
}

.desktop .get-the-app-login {
  color: #3d1b7f;
  font-family: "Gilroy-Bold-☞", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 51px;
  left: 0;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  text-align: center;
  top: 174px;
  width: 277px;
}

.desktop .play-for-your {
  color: #3d1b7f;
  font-family: "Gilroy-Bold-☞", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 85px;
  left: 77px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  text-align: center;
  top: 761px;
  width: 246px;
}

.desktop .p {
  color: #3d1b7f;
  font-family: "Gilroy-Bold-☞", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 51px;
  left: 1013px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 235px;
}

.desktop .text-wrapper-5 {
  color: #3d1b7f;
  font-family: "Gilroy-Bold-☞", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 17px;
  left: 1009px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 689px;
  white-space: nowrap;
}

.desktop .layer-2 {
  height: 118px;
  left: 982px;
  object-fit: cover;
  position: absolute;
  top: 537px;
  width: 149px;
}

.desktop .layer-3 {
  height: 118px;
  left: 127px;
  object-fit: cover;
  position: absolute;
  top: 243px;
  width: 149px;
}

.desktop .layer-4 {
  height: 99px;
  left: 189px;
  object-fit: cover;
  position: absolute;
  top: 614px;
  width: 96px;
}

.desktop .get-the-app-and-win {
  color: transparent;
  font-family: "Gilroy-ExtraBold-Regular", Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  height: 47px;
  left: 64px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3232px;
  white-space: nowrap;
}

.desktop .text-2 {
  color: #363636;
  font-family: "Gilroy-ExtraBold-☞", Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  height: 47px;
  left: 342px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2895px;
  white-space: nowrap;
}

.desktop .overlap-2 {
  height: 714px;
  left: 0;
  position: absolute;
  top: 2110px;
  width: 1440px;
}

.desktop .get-the-app-and-win-2 {
  color: transparent;
  font-family: "Gilroy-ExtraBold-Regular", Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  height: 47px;
  left: 409px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 70px;
  white-space: nowrap;
}

.desktop .group-3 {
  background-color: #3d1b7f;
  height: 714px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.desktop .win-products-from {
  color: transparent;
  font-family: "Gilroy-ExtraBold-Regular", Helvetica;
  font-size: 66.6px;
  font-weight: 400;
  height: 47px;
  left: 160px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 71px;
  white-space: nowrap;
}

.desktop .text-wrapper-6 {
  color: #ffffff;
}

.desktop .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  gap: 64px;
  left: 125px;
  position: absolute;
  top: 239px;
}

.desktop .frame-6 {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #8856e8;
  border-radius: 8px;
  box-shadow: 0px 4px 30px #d3bdff;
  height: 361.75px;
  margin-bottom: -4px;
  margin-left: -4px;
  margin-top: -4px;
  overflow: hidden;
  position: relative;
  width: 361.75px;
}

.desktop .image-4 {
  height: 342px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 322px;
}

.desktop .frame-7 {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #8856e8;
  border-radius: 8px;
  box-shadow: 0px 4px 30px #d3bdff;
  height: 361.75px;
  margin-bottom: -4px;
  margin-top: -4px;
  overflow: hidden;
  position: relative;
  width: 361.75px;
}

.desktop .image-5 {
  height: 326px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 14px;
  width: 326px;
}

.desktop .frame-8 {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #8856e8;
  border-radius: 8px;
  box-shadow: 0px 4px 30px #d3bdff;
  height: 361.75px;
  margin-bottom: -4px;
  margin-right: -4px;
  margin-top: -4px;
  overflow: hidden;
  position: relative;
  width: 361.75px;
}

.desktop .overlap-group-2 {
  height: 342px;
  position: relative;
  top: 6px;
  width: 406px;
}

.desktop .image-6 {
  height: 342px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 354px;
}

.desktop .rectangle-2 {
  background-color: #ffffff;
  height: 264px;
  left: 318px;
  position: absolute;
  top: 7px;
  width: 88px;
}

.desktop .scan-code-to {
  color: #363636;
  font-family: "Gilroy-ExtraBold-☞", Helvetica;
  font-size: 36px;
  font-weight: 400;
  height: 75px;
  left: 885px;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  top: 3761px;
  width: 452px;
}

.desktop .overlap-wrapper {
  background-color: #ffffff;
  border-radius: 40.06px;
  height: 538px;
  left: 169px;
  overflow: hidden;
  position: absolute;
  top: 3494px;
  width: 265px;
}

.desktop .overlap-3 {
  height: 561px;
  position: relative;
  width: 267px;
}

.desktop .group-4 {
  height: 561px;
  left: 0;
  position: absolute;
  top: 0;
  width: 267px;
}

.desktop .overlap-group-3 {
  height: 538px;
  position: relative;
  width: 265px;
}

.desktop .image-7 {
  height: 527px;
  left: 6px;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 254px;
}

.desktop .image-8 {
  height: 538px;
  left: 0;
  position: absolute;
  top: 0;
  width: 265px;
}

.desktop .rectangle-3 {
  background-color: #0684ff;
  height: 12px;
  left: 114px;
  position: absolute;
  top: 131px;
  width: 33px;
}

.desktop .text-wrapper-7 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 119px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 129px;
}

.desktop .frame-9 {
  background-color: #ffffff;
  border-radius: 40.06px;
  height: 538px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 265px;
}

.desktop .overlap-4 {
  height: 538px;
  position: relative;
}

.desktop .microsoftteams-image {
  height: 527px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 252px;
}

.desktop .microsoftteams-image-2 {
  height: 24px;
  left: 33px;
  position: absolute;
  top: 150px;
  width: 38px;
}

.desktop .group-5 {
  background-image: url(../../../static/img/image-4.png);
  background-size: 100% 100%;
  height: 538px;
  left: 0;
  position: absolute;
  top: 0;
  width: 265px;
}

.desktop .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11.33px;
  left: 596px;
  position: absolute;
  top: 3571px;
}

.desktop .image-9 {
  height: 65.88px;
  position: relative;
  width: 218.9px;
}

.desktop .image-10 {
  height: 65.17px;
  position: relative;
  width: 216.77px;
}

.desktop .frame-11 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 628px;
  position: absolute;
  top: 3422px;
}

.desktop .text-wrapper-8 {
  color: #393939;
  font-family: "Gilroy-Medium-☞", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26.7px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.desktop .text-wrapper-9 {
  color: #393939;
  font-family: "Gilroy-Medium-☞", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26.7px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.desktop .bzinga-app-QR {
  height: 261px;
  left: 591px;
  position: absolute;
  top: 3763px;
  width: 261px;
}

.desktop .overlap-5 {
  height: 810px;
  left: 0;
  position: absolute;
  top: 181px;
  width: 1440px;
}

.desktop .frame-12 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  left: 699px;
  position: absolute;
  top: 799px;
}

.desktop .ellipse {
  background-color: #5d2684;
  border-radius: 5.33px;
  height: 10.67px;
  position: relative;
  width: 10.67px;
}

.desktop .ellipse-2 {
  background-color: #f1ddff;
  border-radius: 3.81px;
  height: 7.62px;
  position: relative;
  width: 7.62px;
}

.desktop .play-auction-and {
  height: 810px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1440px;
}

.desktop .frame-13 {
  align-items: flex-start;
  display: inline-flex;
  gap: 92px;
  justify-content: flex-end;
  left: 76px;
  position: absolute;
  top: 3015px;
}

.desktop .group-6 {
  background-color: #ffffff;
  border: 0.75px solid;
  border-color: #979797;
  border-radius: 11.96px;
  height: 97.9px;
  position: relative;
  width: 252.6px;
}

.desktop .image-11 {
  height: 38px;
  left: 41px;
  position: absolute;
  top: 29px;
  width: 169px;
}

.desktop .image-12 {
  height: 62px;
  left: 58px;
  position: absolute;
  top: 17px;
  width: 137px;
}

.desktop .image-13 {
  height: 79px;
  left: 50px;
  object-fit: cover;
  position: absolute;
  top: 9px;
  width: 151px;
}

.desktop .image-14 {
  height: 87px;
  left: 82px;
  object-fit: cover;
  position: absolute;
  top: 5px;
  width: 87px;
}

.desktop .group-7 {
  height: 48px;
  left: 0;
  position: absolute;
  top: 4134px;
  width: 1442px;
}

.desktop .p-wrapper {
  background-color: #ff9c09;
  height: 48px;
  position: relative;
  width: 1440px;
}

.desktop .text-wrapper-10 {
  color: #393939;
  font-family: "Gilroy-SemiBold-☞", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 14px;
  left: 440px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.desktop .ellipse-3 {
  background-color: #3d1b7f;
  border-radius: 6px;
  height: 12px;
  left: 596px;
  position: absolute;
  top: 3422px;
  width: 12px;
}

.desktop .ellipse-4 {
  background-color: #3d1b7f;
  border-radius: 6px;
  height: 12px;
  left: 596px;
  position: absolute;
  top: 3462px;
  width: 12px;
}

.desktop .ellipse-5 {
  background-color: #3d1b7f;
  border-radius: 6px;
  height: 12px;
  left: 596px;
  position: absolute;
  top: 3500px;
  width: 12px;
}
